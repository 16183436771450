$(function() {
	var referencesSwiper = new Swiper('.con_references-box .swiper-container', {
		speed: 400,
		spaceBetween: 0,
		loop: false,
		slidesPerView: 5,
		spaceBetween: 15,		
		autoplay: {
			delay: 5000,
			disableOnInteraction: false
		},
		breakpoints: {
			// when window width is <= 575px
			575: {
			  slidesPerView: 1
			},
			// when window width is <= 767px
			767: {
			  slidesPerView: 2
			},
			// when window width is <= 991px
			991: {
				slidesPerView: 3
			},
			// when window width is <= 1199px
			1199: {
				slidesPerView: 4
			}
		  }
	});

	$(".anchor-scroll").click(function(event){
        //prevent the default action for the click event
        event.preventDefault();

        //get the full url - like mysitecom/index.htm#home
        var full_url = this.href;

        //split the url by # and get the anchor target name - home in mysitecom/index.htm#home
        var parts = full_url.split("#");
        var trgt = parts[1];

        //get the top offset of the target anchor
        var target_offset = $("#"+trgt).offset();        
        var target_top = target_offset.top - 65;

        //goto that anchor by setting the body scroll top to anchor top
        $('html, body').animate({scrollTop:target_top}, 900, 'easeOutQuint');
	});
	
	$(window).scroll(function() {

        if ($(this).scrollTop() > 80) {
            $("header.navbar").addClass('scrolled');
        } else  {
            $("header.navbar").removeClass("scrolled");
        }

    });
});